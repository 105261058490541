import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditModal = ({ setOpenEditModal, userId }) => {
  const [fetchUser, setFetchUser] = useState({
    fullName: "",
    email: "",
    plan: "",
    credits: "",
    payment_status: "",
  });

  const { fullName, email, plan, credits, payment_status } = fetchUser;

  const handleChange = (e) => {
    setFetchUser({ ...fetchUser, [e.target.name]: e.target.value });
  }

  const getUserDetails = async() => {
    try {
      const res = await axios.post("https://app.easify-ai.com/admin/fetch_user", { userId });
      if(res) {
        console.log(res);
        setFetchUser(res.data.user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleEditUser = async(id) => {
    try {
      const formData = {
        id,
        fullName, email, plan, credits, payment_status
      }
      const res = await axios.put("https://app.easify-ai.com/admin/update_user", formData);
      console.log(res);
      if(res.data.message === "User Updated Successfully") {
        toast.success(res.data.message, {
          position: "top-right"
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      else {
        toast.error(res.data.message, {
          position: "top-right"
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, [])

  return (
    <>
      <div
        className="relative z-[99]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <ToastContainer autoClose={4000}/>
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-[#0e131f] text-left shadow-xl transition-all sm:my-8 w-full md:max-w-2xl">
              <div className="bg-[#0e131f] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between w-full border-b pb-3 border-gray-700">
                  <div className="flex items-center gap-2">
                    <h3 className="text-white font-semibold text-base">
                      Edit User Details
                    </h3>
                  </div>
                </div>
                <div className="pt-4 pb-2">
                  <div className="w-full flex flex-col gap-2 mb-3">
                    <label
                      htmlFor="fullName"
                      className="text-white font-semibold text-sm"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      value={fullName || ""}
                      onChange={handleChange}
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2 mb-3">
                    <label
                      htmlFor="email"
                      className="text-white font-semibold text-sm"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email || ""}
                      onChange={handleChange}
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2 mb-3">
                    <label
                      htmlFor="credits"
                      className="text-white font-semibold text-sm"
                    >
                      Credits
                    </label>
                    <input
                      type="tel"
                      name="credits"
                      value={credits || ""}
                      onChange={handleChange}
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2 mb-3">
                    <label
                      htmlFor="plan"
                      className="text-white font-semibold text-sm"
                    >
                      Plan
                    </label>
                    <input
                      type="text"
                      name="plan"
                      value={plan || ""}
                      onChange={handleChange}
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <label
                      htmlFor="payment_status"
                      className="text-white font-semibold text-sm"
                    >
                      Payment Status
                    </label>
                    <input
                      type="text"
                      name="payment_status"
                      value={payment_status || ""}
                      onChange={handleChange}
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-[#1f2531] py-4 md:pr-6 pr-5 flex gap-4 items-center justify-end">
                <button
                  type="button"
                  onClick={() => handleEditUser(userId)}
                  className="inline-flex justify-center transition rounded-md bg-[#8d57ff] px-3 w-20 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1100d0]"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setOpenEditModal(false)}
                  className="inline-flex justify-center transition rounded-md bg-white px-3 w-20 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditModal;
