import axios from "axios";
import React, { useEffect } from "react";
import Loader from "../components/Loader";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";

const Success = () => {
  const sessionId = localStorage.getItem("session_id");
  const token = localStorage.getItem("user_auth");
  const { loading, handleLoader } = useGlobalContext();

  const getPaymentStatus = async () => {
    try {
      const formData = { sessionId, token };
      const res = await axios.post(
        "https://app.easify-ai.com/payment/get-status",
        formData,
        { withCredentials: true }
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  // const updatePaymentStatus = async () => {
  //   try {
  //     const formData = { token, paymentStatus, totalCredits };
  //     const res = await axios.post(
  //       "http://localhost:8080/payment/update-status",
  //       formData
  //     );
  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  
  useEffect(() => {
    handleLoader();
    // updatePaymentStatus();
    getPaymentStatus();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-xs mx-auto mt-14 bg-[#0e131f] shadow-sm rounded-md p-6">
          <div className="flex flex-col gap-2 justify-center items-center">
            <img src="/checked.png" className="w-20 h-20" alt="success" />
            <div>
              <h2 className="text-white text-lg mb-1 font-semibold">
                Payment Successful!
              </h2>
              <p className="text-white text-sm">Thank you for your payment.</p>
            </div>
            <Link
              to="/profile"
              type="button"
              className="w-full text-center register-btn mt-2 p-2 rounded-md text-white"
            >
              Back to Profile
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Success;
