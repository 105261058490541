import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const Login = () => {
  const statusToken = localStorage.getItem("verification_status");
  const initialState = {
    email: "",
    password: "",
  };

  const {
    showPassword,
    setShowPassword,
    handleShowPassword,
    loading,
    handleLoader,
  } = useGlobalContext();
  
  const [userData, setUserData] = useState(initialState);
  const { email, password } = userData;

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    try {
      if (statusToken !== "false") {
        const formData = { email, password };
        const res = await axios.post(
          "https://app.easify-ai.com/users/login",
          formData
        );
        if (res.data.message === "You have successfully logged in!") {
          toast.success(res.data.message, {
            position: "top-right",
          });
          localStorage.setItem("user_auth", res.data.token);
          localStorage.setItem("verification_status", "true");
          setTimeout(() => {
            window.location.href = "/data-ai";
          }, 2000);
        } else {
          toast.error(res.data.message, {
            position: "top-right",
          });
        }
      } else {
        toast.error("Please Verify your account", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full">
          <div className="max-w-lg md:mx-auto mx-7 shadow-md rounded-md p-4 mt-28 bg-[#0e131f]">
            <ToastContainer autoClose={3000} />
            <h3 className="text-white text-center text-lg pb-2 font-bold border-b-2 border-gray-800 mb-4">
              Login to your account
            </h3>
            <div className="flex flex-col items-start gap-4">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="text-white text-base font-semibold"
                >
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={handleChange}
                  className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-2 outline-none "
                />
              </div>
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="password"
                  className="text-white text-base font-semibold"
                >
                  Password
                </label>
                <div className="password-box bg-[#1f2937] rounded-md p-2 flex items-center justify-between">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className="w-full bg-transparent text-white text-sm outline-none"
                  />
                  {showPassword ? (
                    <i
                      className="fas fa-eye-slash cursor-pointer text-white text-sm mr-1"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye cursor-pointer text-white text-sm mr-1"
                      onClick={handleShowPassword}
                    ></i>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col items-start md:flex-row md:items-center md:gap-0 gap-2 justify-between">
                <div className="flex items-center gap-1">
                  <span className="text-base text-white font-semibold">
                    Dont have an account?
                  </span>
                  <Link
                    to="/register"
                    className="text-base text-purple-300 font-semibold hover:underline"
                  >
                    Sign Up!
                  </Link>
                </div>
                <Link
                  to="/forgot-password"
                  className="text-base text-purple-300 font-semibold hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="button"
                className="text-white font-semibold text-base rounded-md p-3 register-btn"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
