import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import Plot from "./Plot";
import { RiQuestionnaireLine } from "react-icons/ri";
import { FcAcceptDatabase } from "react-icons/fc";
import { BsDatabaseGear, BsPatchPlus, BsBookHalf } from "react-icons/bs";
import DatabaseModal from "../components/DatabaseModal";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatabaseList from "../components/DatabaseList";

const DataAi = () => {
  const token = localStorage.getItem("user_auth");
  const {
    handleSidebar,
    setOpenSidebar,
    handleModal,
    showDbTable,
    handleShowDbTable,
    openModal,
    loading,
    handleLoader,
  } = useGlobalContext();

  const [userData, setUserData] = useState([]);
  const [history, setHistory] = useState([]);
  const [text, setText] = useState("Generate");
  const [credits, setCredits] = useState(0);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState([]);
  const [copied, setCopied] = useState(false);
  const [showGraphBox, setShowGraphBox] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [plotData, setPlotData] = useState("");
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [databaseDetails, setDatabaseDetails] = useState([]);
  const [showSelectedDb, setShowSelectedDb] = useState(false);
  const [visualizeText, setVisualizeText] = useState("Visualize");
  const [stopBtn, setStopBtn] = useState(false);
  const [cancelToken, setCancelToken] = useState(null);
  let historyHandled = false;

  const stopGenerating = () => {
    if(cancelToken) {
      cancelToken.cancel("Operation canceled by the user.");
      setShowSelectedDb(false);
      setSelectedDatabase(null);
      setStopBtn(false);
      setCancelToken(null);
      setText("Generate");
    }
  }

  const handleVisualizeText = () => {
    setVisualizeText("Visualising...");
  };

  const handleSelectDatabase = (e) => {
    const value = e.target.value;
    setSelectedDatabase(value);
    fetchSelectedDatabase(value);
    setShowSelectedDb(true);
  };

  const fetchSelectedDatabase = async (value) => {
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/fetch-database",
        { token, selectedDatabase: value }
      );
      setDatabaseDetails(res.data.findSelectedDatabase);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async () => {
    const formData = { token };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/getUser",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(res.data.user);
      setCredits(res.data.user.credits);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user_auth");
    localStorage.removeItem("session_id");
    localStorage.removeItem("verification_status");
    Cookies.remove("status");
    Cookies.remove("credits");
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const handleChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleCredits = async () => {
    try {
      const formData = {
        token,
        credits,
      };
      const res = await axios.post(
        "https://app.easify-ai.com/users/credits",
        formData
      );
      if (res.data) {
        setCredits(res.data.credits);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleHistory = async (answers) => {
    if (historyHandled) {
      return;
    }
    const formData = { question, token, answers };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/history",
        formData
      );
      if (res) {
        console.log(res.data);
        setHistory(res.data.user.history);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitRequest = async () => {
    if (question) {
      if (credits < 1) {
        toast.error("Insufficient credits", {
          position: "top-right",
        });
      } else {
        try {
          if(selectedDatabase) {
            const formData = { query: question };
            setText("Generating...");
            setStopBtn(true);
            
            const source = axios.CancelToken.source();
            setCancelToken(source);

            const proxyUrl = "https://app.easify-ai.com/users/dataquery";
            const res = await axios.post(proxyUrl, {
              input: formData,
              database_uri: selectedDatabase,
            }, {
              cancelToken: source.token,
            });

            setText("Generate");
            setStopBtn(false);
            setAnswer(res.data);
            handleCredits();
            handleHistory(res.data);
          } else {
            toast.error("Please select atleast one database", {
              position: "top-right",
            });
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    } else {
      toast.error("The above field is required", {
        position: "top-right",
      });
    }
  };

  const copyToClipboard = (answer) => {
    const textToCopy = answer;
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
  };

  const handleVisualize = async (answer) => {
    try {
      const data = {
        input: answer,
      };
      const res = await axios.post(
        "https://app.easify-ai.com/users/visualize",
        data
      );
      if (res.status === 200) {
        console.log(res);
        setVisualizeText("Visualize");
        setPlotData(res.data.image);
        setShowGraphBox(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  useEffect(() => {
    setOpenSidebar(false);
    if (token) {
      fetchUser();
    } else {
      console.log("You have to login");
    }
  }, [token]);

  return (
    <>
      <Sidebar />
      {openModal && <DatabaseModal />}
      {showDbTable && (
        <DatabaseList
          handleSelectDatabase={handleSelectDatabase}
          selectedDatabase={selectedDatabase}
          fetchSelectedDatabase={fetchSelectedDatabase}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <aside className="bg-[#0e131f] dk-sidebar hidden md:block overflow-y-scroll fixed top-0 left-0 bottom-0 h-full w-64 z-50">
            <div className="w-full h-full flex flex-col items-start justify-between py-10 px-5">
              <div className="w-full flex flex-col items-start">
                <Link
                  to="/data-ai"
                  className="w-full pb-2 mb-4 border-b border-gray-600"
                >
                  <img
                    src="/easify-ai-logo.png"
                    alt="logo"
                    className="w-[70px]"
                  />
                </Link>
                <ul className="w-[inherit] pt-8">
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsDatabaseGear size={18} />
                    <Link to="/data-ai">Data AI</Link>
                  </li>
                  <li
                    onClick={handleModal}
                    className="text-white cursor-pointer text-base font-semibold flex items-center gap-2 mb-6"
                  >
                    <BsPatchPlus size={18} />
                    <span>Create Connection</span>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsBookHalf size={18} />
                    <Link to="/history">History</Link>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2">
                    <RiQuestionnaireLine size={18} />
                    <Link to="https://easify-ai.com/faq" target="_blank">
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full flex flex-col border-t-2 border-gray-600">
                <Link
                  to="/profile"
                  type="button"
                  className="mt-3 text-center register-btn text-white font-semibold text-base rounded-md p-2"
                >
                  Upgrade Plan <i className="fas fa-chevron-right pl-1"></i>
                </Link>
                <div className="flex items-center justify-between mt-2">
                  <div className="w-full flex flex-col">
                    <Link
                      to="/profile"
                      className="font-semibold text-sm text-[#d1d5db]"
                    >
                      {userData.fullName}
                    </Link>
                    <div className="flex items-center gap-2 mt-2">
                      <Link
                        to="/profile"
                        className="bg-[#8b5cf6] rounded-md text-sm font-semibold w-[28px] h-[28px] flex items-center justify-center text-white"
                      >
                        {credits}
                      </Link>
                      <span className="text-xs text-white font-semibold">
                        Credits
                      </span>
                      <i
                        className="fa-solid fa-right-from-bracket text-base ml-auto cursor-pointer text-white"
                        onClick={handleLogout}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>

          <header className="w-full bg-transparent block md:hidden">
            <div className="flex items-center justify-between py-4 px-6 border-b-2 border-gray-800">
              <Link to="/data-ai" className="w-full">
                <img
                  src="/easify-ai-logo.png"
                  alt="logo"
                  className="w-[70px]"
                />
              </Link>
              <i
                className="fa-solid fa-bars text-white text-lg cursor-pointer"
                onClick={handleSidebar}
              ></i>
            </div>
          </header>

          <div className="flex flex-col items-start justify-center py-14 px-6 md:py-20 md:px-72">
            <ToastContainer autoClose={4000} />
            <h3 className="text-white text-2xl font-bold mb-2">Data AI</h3>
            <div className="w-full flex flex-col gap-3 mt-4 border-t-2 border-gray-600 pt-4">
              <label
                htmlFor="question"
                className="text-gray-400 text-lg font-semibold"
              >
                Enter Your Query
              </label>
              <input
                type="text"
                name="question"
                value={question}
                onChange={handleChange}
                className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input mb-3"
              />
            </div>
            <div className="flex items-center mt-3 gap-3">
              {text === "Generating..." ? (
                <button disabled type="button" className="register-btn inline-flex items-center gap-1 text-white text-sm font-semibold rounded-md p-3 opacity-75">
                  <svg aria-hidden="true" role="status" className="inline w-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                  </svg>
                  <span>{text}</span>
                </button>
              ) : (
                <button
                  onClick={submitRequest}
                  type="button"
                  className="register-btn text-white text-sm font-semibold rounded-md p-3"
                >
                {text}
                </button>
              )}
              {stopBtn && (
                <button type="button" onClick={stopGenerating} className="bg-[#1100d0] text-white text-sm font-semibold rounded-md p-3 transition-colors hover:bg-[#8b5cf6]">Stop Generating</button>
              )}
              <button
                type="button"
                onClick={handleShowDbTable}
                className="bg-[#1f2937] flex items-center gap-2 text-white text-sm font-semibold rounded-md p-3"
              >
                <FcAcceptDatabase size={20} /> Saved Databases
              </button>
            </div>
            {showSelectedDb && (
              <div className="bg-[#1f2937] w-full md:w-1/2 rounded-md mt-6 p-4 flex items-center justify-between">
                <div className="flex flex-col justify-start">
                  <h2 className="text-xl text-white font-semibold mb-2">
                    Selected Database Details:
                  </h2>
                  <p className="text-white">
                    <strong>Database Name:</strong> {databaseDetails.name}
                  </p>
                  <p className="text-white">
                    <strong>Connection Name:</strong> {databaseDetails.database}
                  </p>
                </div>
                {databaseDetails.name === "Mongo DB" && (
                  <img
                    src="/leaf.png"
                    className="w-12 object-cover"
                    alt={databaseDetails.name}
                  />
                )}
                {databaseDetails.name === "MySQL" && (
                  <img
                    src="/programing.png"
                    className="w-12 object-cover"
                    alt={databaseDetails.name}
                  />
                )}
                {databaseDetails.name === "SQLite" && (
                  <img
                    src="/database.png"
                    className="w-12 object-cover"
                    alt={databaseDetails.name}
                  />
                )}
                {(databaseDetails.name === "Postgre SQL" || databaseDetails.name === "postgres") && (
                  <img
                    src="/postgre.png"
                    className="w-12 object-cover"
                    alt={databaseDetails.name}
                  />
                )}
              </div>
            )}
            {answer.length > 0 && (
              <div className="bg-[#1f2937] w-full rounded-md mt-6 p-4 relative">
                {copied ? (
                  <i
                    className="fa-solid fa-circle-check absolute text-white cursor-pointer top-4 right-4"
                    onClick={() => setCopied(false)}
                  ></i>
                ) : (
                  <i
                    className="fa-solid fa-pen-to-square absolute text-white cursor-pointer top-4 right-4"
                    onClick={() => copyToClipboard(answer)}
                  ></i>
                )}
                <p className="text-gray-300 text-sm font-medium mt-6">
                  {answer}
                </p>
                {visualizeText === "Visualising..." ? (
                  <button disabled type="button" className="register-btn my-4 inline-flex items-center gap-1 text-white text-sm font-semibold rounded-md p-3 opacity-75">
                    <svg aria-hidden="true" role="status" className="inline w-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                    </svg>
                    <span>{visualizeText}</span>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleVisualizeText();
                      handleVisualize(answer);
                    }}
                    type="button"
                    className="my-4 register-btn font-semibold text-white p-2 rounded-md"
                  >
                  {visualizeText}
                  </button>
                )}
              </div>
            )}
            {showGraphBox === true &&
              (showGraph ? (
                <div className="mt-8 text-white text-lg font-semibold">
                  Graph cannot be found.
                </div>
              ) : (
                <Plot plotData={plotData} />
              ))}
          </div>
        </>
      )}
    </>
  );
};

export default DataAi;
