import React, { useEffect } from 'react'
import Header from '../components/Header'
import Loader from "../components/Loader";
import { useGlobalContext } from "../context/context";

const Home = () => {

  const { loading, handleLoader } = useGlobalContext();

  useEffect(() => {
    handleLoader();
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Header />
      )}
    </>
  )
}

export default Home