import React, { useState } from "react";
import { useGlobalContext } from "../context/context";
import { BsDatabaseAdd } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import axios from "axios";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DatabaseModal = () => {
  const initialState = {
    host: "",
    database: "",
    username: "",
    password: "",
    port: "",
  };

  const token = localStorage.getItem("user_auth");
  const [databaseConfig, setDatabaseConfig] = useState(initialState);
  const {
    setOpenModal,
    databaseDivs,
    toggleDBType,
    selectDBType,
    dbType,
    setDBType,
    dbPassword,
    handleDBPassword
  } = useGlobalContext();

  const { host, database, username, password, port } = databaseConfig;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDatabaseConfig({ ...databaseConfig, [name]: value });
  };

  const handleUpdateDatabase = async () => {
    try {
      const res = await axios.post("https://app.easify-ai.com/users/update-url", {
        databaseConfig,
        token,
        dbType,
      });
      if (res.data.message === "Database added successfully") {
        toast.success(res.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        className="relative z-[99]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <ToastContainer autoClose={4000} />
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-[#0e131f] text-left shadow-xl transition-all sm:my-8 w-full md:max-w-2xl">
              <div className="bg-[#0e131f] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between w-full border-b pb-3 border-gray-700">
                  <div className="flex items-center gap-2">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#8d57ff] text-white sm:mx-0 sm:h-10 sm:w-10">
                      <BsDatabaseAdd size={20} />
                    </div>
                    <h3 className="text-white font-semibold text-base">
                      Create Connection
                    </h3>
                  </div>
                  <button
                    onClick={() => setOpenModal(false)}
                    type="button"
                    className="text-white font-semibold text-base bg-[#1f2531] rounded-full w-10 h-10 flex items-center justify-center text-center hover:opacity-70"
                  >
                    <MdOutlineClose size={20} />
                  </button>
                </div>
                <div className="w-full flex items-center justify-between gap-3 pt-4">
                  {databaseDivs.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          toggleDBType(index);
                          setDBType(item.label);
                        }}
                        className={`w-1/4 cursor-pointer flex flex-col gap-1.5 items-center justify-center bg-gray-700 border-2 ${
                          selectDBType === index
                            ? "border-blue-700"
                            : "border-gray-700"
                        } shadow-sm p-2.5 rounded`}
                      >
                        <img
                          src={item.src}
                          className="w-10 object-cover"
                          alt={item.alt}
                        />
                        <h4 className="text-white hidden md:block font-semibold">
                          {item.label}
                        </h4>
                      </div>
                    );
                  })}
                </div>
                <div className="w-full pt-4">
                  <div className="flex flex-col items-start gap-2 pb-3">
                    <label
                      htmlFor="name"
                      className="text-white text-base font-semibold"
                    >
                      Name<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <input
                      value={dbType}
                      onChange={handleChange}
                      type="text"
                      disabled
                      placeholder="Database Name"
                      name="name"
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-2 pb-2">
                    <label
                      htmlFor="host"
                      className="text-white text-base font-semibold"
                    >
                      Host<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <input
                      value={host}
                      onChange={handleChange}
                      type="text"
                      name="host"
                      placeholder="Connection Host"
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-2 pb-2">
                    <label
                      htmlFor="database"
                      className="text-white text-base font-semibold"
                    >
                      Database<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <input
                      value={database}
                      onChange={handleChange}
                      type="text"
                      name="database"
                      placeholder="Connection Name"
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-2 pb-2">
                    <label
                      htmlFor="username"
                      className="text-white text-base font-semibold"
                    >
                      User<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <input
                      value={username}
                      onChange={handleChange}
                      type="text"
                      placeholder="Connection User"
                      name="username"
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-2 pb-2">
                    <label
                      htmlFor="password"
                      className="text-white text-base font-semibold"
                    >
                      Password<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <div className="form-control flex items-center justify-between w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input">
                      <input
                        value={password}
                        onChange={handleChange}
                        type={dbPassword ? "text" : "password"}
                        placeholder="Connection Password"
                        name="password"
                        className="w-[inherit] bg-transparent outline-none border-none"
                      />
                      {dbPassword ? (
                        <AiFillEyeInvisible
                          className="cursor-pointer"
                          onClick={handleDBPassword}
                          size={20}
                        />
                      ) : (
                        <AiFillEye
                          className="cursor-pointer"
                          onClick={handleDBPassword}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-2 pb-2">
                    <label
                      htmlFor="port"
                      className="text-white text-base font-semibold"
                    >
                      Port<span className="text-[#ff5a5f]">*</span>
                    </label>
                    <input
                      value={port}
                      onChange={handleChange}
                      type="tel"
                      placeholder="Port"
                      name="port"
                      className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-3 outline-none focus-input"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-[#1f2531] py-4 md:pr-6 pr-5 flex gap-4 items-center justify-end">
                <button
                  type="button"
                  onClick={handleUpdateDatabase}
                  className="inline-flex justify-center transition rounded-md bg-[#8d57ff] px-3 w-20 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1100d0]"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setOpenModal(false)}
                  className="inline-flex justify-center transition rounded-md bg-white px-3 w-20 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatabaseModal;
