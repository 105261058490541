import React, { useState, useEffect } from "react";
import axios from "axios";
import { useGlobalContext } from "../context/context";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const CreateNewPassword = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const resetId = params.get("reset_passsword_id");

  const {
    showConfirmPassword,
    handleShowConfirmPassword,
    setShowConfirmPassword,
    showPassword,
    handleShowPassword,
    setShowPassword,
    loading,
    handleLoader
  } = useGlobalContext();

  const [createPasswordData, setCreatePasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const { password, confirmPassword } = createPasswordData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreatePasswordData({ ...createPasswordData, [name]: value });
  };

  const handleCreateNewPassword = async () => {
    try {
      const res = await axios.put(
        "https://app.easify-ai.com/users/create-password",
        { password, confirmPassword, resetId }
      );
      if (res.data.message === "Password updated successfully") {
        toast.success(res.data.message, {
          position: "top-right",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 1500);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full">
          <div className="max-w-lg md:mx-auto mx-7 shadow-md rounded-md p-4 mt-28 bg-[#0e131f]">
            <ToastContainer autoClose={4000} />
            <h3 className="text-white text-center text-lg pb-2 font-bold border-b-2 border-gray-800 mb-4">
              Create New Password
            </h3>
            <div className="flex flex-col items-start gap-4">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="password"
                  className="text-white text-base font-semibold"
                >
                  Password
                </label>
                <div className="password-box bg-[#1f2937] rounded-md p-2 flex items-center justify-between">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className="w-full bg-transparent text-white text-sm outline-none"
                  />
                  {showPassword ? (
                    <i
                      className="fas fa-eye-slash cursor-pointer text-white text-sm mr-1"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye cursor-pointer text-white text-sm mr-1"
                      onClick={handleShowPassword}
                    ></i>
                  )}
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="password"
                  className="text-white text-base font-semibold"
                >
                  Confirm Password
                </label>
                <div className="password-box bg-[#1f2937] rounded-md p-2 flex items-center justify-between">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange}
                    className="w-full bg-transparent text-white text-sm outline-none"
                  />
                  {showConfirmPassword ? (
                    <i
                      className="fas fa-eye-slash cursor-pointer text-white text-sm mr-1"
                      onClick={() => setShowConfirmPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye cursor-pointer text-white text-sm mr-1"
                      onClick={handleShowConfirmPassword}
                    ></i>
                  )}
                </div>
              </div>
              <button
                type="button"
                onClick={handleCreateNewPassword}
                className="text-white font-semibold text-base rounded-md p-3 register-btn"
              >
                Send
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CreateNewPassword;
