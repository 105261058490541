import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import axios from "axios";
import { BsDatabaseGear, BsPatchPlus, BsBookHalf } from "react-icons/bs";
import { RiQuestionnaireLine } from "react-icons/ri";
import DatabaseModal from "../components/DatabaseModal";
import Loader from "../components/Loader";
import Cookies from "js-cookie";

const Profile = () => {
  const token = localStorage.getItem("user_auth");
  const {
    handleSidebar,
    setOpenSidebar,
    handleModal,
    openModal,
    loading,
    handleLoader,
  } = useGlobalContext();

  const [userData, setUserData] = useState([]);
  const [credits, setCredits] = useState(0);
  const [btnText, setBtnText] = useState("Buy Credits");

  const cookieStatus = Cookies?.get("status");
  const cookieCredits = Cookies?.get("credits");

  const handleBtnText = () => {
    setBtnText("Redirecting...");
    setTimeout(() => {
      setBtnText("Buy Credits");
    }, 4000);
  };

  const fetchUser = async () => {
    const formData = { token };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/getUser",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(res.data.user);
      setCredits(res.data.user.credits);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user_auth");
    localStorage.removeItem("session_id");
    localStorage.removeItem("verification_status");
    Cookies.remove("status");
    Cookies.remove("credits");
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const handleSubscribe = async (plan, credits) => {
    try {
      const requestBody = {
        email: userData.email,
        name: userData.fullName,
        plan,
        credits,
      };
      const res = await axios.post(
        "https://app.easify-ai.com/payment/create-subscription-checkout-session",
        requestBody
      );
      if (res.status === 200) {
        localStorage.setItem("session_id", res.data.sessionId);
        setTimeout(() => {
          window.open(res.data.url, "_blank");
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatusAndCredits = async () => {
    try {
      const formData = { token, cookieStatus, cookieCredits };
      const res = await axios.post(
        "https://app.easify-ai.com/payment/update-status-credits",
        formData
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateStatusAndCredits();
  }, []);

  useEffect(() => {
    setOpenSidebar(false);
    if (token) {
      fetchUser();
    } else {
      console.log("You have to login");
    }
  }, [token]);

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      <Sidebar />
      {openModal && <DatabaseModal />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <aside className="bg-[#0e131f] dk-sidebar hidden md:block overflow-y-scroll fixed top-0 left-0 bottom-0 h-full w-64 z-50">
            <div className="w-full h-full flex flex-col items-start justify-between py-10 px-5">
              <div className="w-full flex flex-col items-start">
                <Link
                  to="/data-ai"
                  className="w-full pb-2 mb-4 border-b border-gray-600"
                >
                  <img
                    src="/easify-ai-logo.png"
                    alt="logo"
                    className="w-[70px]"
                  />
                </Link>
                <ul className="w-[inherit] pt-8">
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsDatabaseGear size={18} />
                    <Link to="/data-ai">Data AI</Link>
                  </li>
                  <li
                    onClick={handleModal}
                    className="text-white cursor-pointer text-base font-semibold flex items-center gap-2 mb-6"
                  >
                    <BsPatchPlus size={18} />
                    <span>Create Connection</span>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsBookHalf size={18} />
                    <Link to="/history">History</Link>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2">
                    <RiQuestionnaireLine size={18} />
                    <Link to="https://easify-ai.com/faq" target="_blank">
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full flex flex-col border-t-2 border-gray-600">
                <Link
                  to="/profile"
                  type="button"
                  className="mt-3 text-center register-btn text-white font-semibold text-base rounded-md p-2"
                >
                  Upgrade Plan <i className="fas fa-chevron-right pl-1"></i>
                </Link>
                <div className="flex items-center justify-between mt-2">
                  <div className="w-full flex flex-col">
                    <Link
                      to="/profile"
                      className="font-semibold text-sm text-[#d1d5db]"
                    >
                      {userData.fullName}
                    </Link>
                    <div className="flex items-center gap-2 mt-2">
                      <Link
                        to="/profile"
                        className="bg-[#8b5cf6] rounded-md text-sm font-semibold w-[28px] h-[28px] flex items-center justify-center text-center text-white"
                      >
                        {credits}
                      </Link>
                      <span className="text-xs text-white font-semibold">
                        Credits
                      </span>
                      <i
                        className="fa-solid fa-right-from-bracket text-base ml-auto cursor-pointer text-white"
                        onClick={handleLogout}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>

          <header className="w-full bg-transparent block md:hidden">
            <div className="flex items-center justify-between py-4 px-6 border-b-2 border-gray-800">
              <Link to="/profile" className="w-full">
                <img
                  src="/easify-ai-logo.png"
                  alt="logo"
                  className="w-[70px]"
                />
              </Link>
              <i
                className="fa-solid fa-bars text-white text-lg cursor-pointer"
                onClick={handleSidebar}
              ></i>
            </div>
          </header>

          <div className="flex flex-col items-start justify-center py-14 px-6 md:py-20 md:px-72">
            <h3 className="text-white text-xl font-semibold mb-1">
              Account Settings
            </h3>
            <span className="text-white text-sm">
              View your profile details and update your subscription here.
            </span>
            <div className="w-full bg-gray-900 rounded-md mt-6 p-6">
              <div className="flex flex-col gap-1">
                <span className="text-gray-500 text-sm font-semibold">
                  Name
                </span>
                <span className="text-white text-base font-semibold">
                  {userData.fullName}
                </span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <span className="text-gray-500 text-sm font-semibold">
                  Email ID
                </span>
                <span className="text-white text-base font-semibold">
                  {userData.email}
                </span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <span className="text-gray-500 text-sm font-semibold">
                  Credits Remaining
                </span>
                <span className="text-white text-base font-semibold">
                  {credits}
                </span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <span className="text-gray-500 text-sm font-semibold">
                  Payment Status
                </span>
                <span className="text-white text-base font-semibold">
                  {userData.payment_status}
                </span>
              </div>
              <div className="flex flex-col gap-1 mt-4">
                <span className="text-gray-500 text-sm font-semibold">
                  Subscription
                </span>
                <Link
                  type="button"
                  className="register-btn mt-[8px] text-center p-2 rounded-md text-white font-medium"
                  to="https://billing.stripe.com/p/login/aEU4jr0oBdFQ1YQcMM"
                >
                  Manage Subscription
                </Link>
              </div>
            </div>
            <div className="w-full bg-gray-900 rounded-md mt-8 p-6">
              <h3 className="text-white text-lg font-semibold mb-1">
                Credit Plans
              </h3>
              <span className="text-white text-sm">
                Update your credits plan. You receive the corresponding credits
                immediately after you purchase the credits and the credits are
                renewed every month.
              </span>
              <div className="w-full overflow-x-auto relative mt-4">
                <table className="w-full table-auto">
                  <tbody className="border-y border-gray-600">
                    <tr className="border-b-2 border-gray-600 hover:bg-gray-500 bg-gray-800">
                      <td className="text-gray-300 font-semibold text-sm px-2 py-4">
                        100 Credits per Month
                      </td>
                      <td className="text-gray-300 font-semibold text-sm px-2 py-4">
                        $0.1/credit
                      </td>
                      <td className="text-gray-300 font-semibold text-sm px-2 py-4">
                        $10/month
                      </td>
                      <td className="font-semibold md:text-sm text-xs px-2 py-4 flex justify-end text-violet-300">
                        <button
                          onClick={() => {
                            handleBtnText();
                            handleSubscribe("Basic", 100);
                          }}
                          type="button"
                          disabled={btnText === "Redirecting..." ? true : false}
                          className="register-btn text-center p-2 rounded-md text-white font-medium"
                        >
                          {btnText}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Profile;
