import React, { useState, useContext } from "react";
import axios from "axios";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const token = localStorage.getItem("user_auth");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [savedModal, setSavedModal] = useState(false);
    const [selectDBType, setSelectDBType] = useState(null);
    const [showDbTable, setShowDbTable] = useState(false);
    const [editDatabaseModal, setEditDatabaseModal] = useState(false);
    const [dbPassword, setDBPassword] = useState(false);
    const [dbType, setDBType] = useState('');
    const [fetchedDatabase, setFetchedDatabase] = useState([]);

    const databaseDivs = [
        { src: "/programing.png", alt: "mysql", label: "MySQL" },
        { src: "/postgre.png", alt: "postgresql", label: "Postgre SQL" },
        { src: "/database.png", alt: "sqlite", label: "SQLite" },
        { src: "/leaf.png", alt: "mongodb", label: "Mongo DB" }
    ];

    const handleDBPassword = () => {
        setDBPassword(!dbPassword);
    }

    const handleShowDbTable = () => {
        setShowDbTable(true);
    }

    const handleEditDatabaseModal = () => {
        setEditDatabaseModal(true);
    }

    const toggleDBType = (index) => {
        setSelectDBType(index);
    }

    const handleLoader = () => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const getDatabaseById = async (Id) => {
        try {
          const res = await axios.post('https://app.easify-ai.com/users/database-single', { token, databaseId: Id });
          setFetchedDatabase(res.data.fetchedDatabase);
        } catch (error) {
          console.error(error);
        }
    }

    const handleModal = () => {
        setOpenModal(true);
    }

    const handleShowPassword = () => {
        setShowPassword(true);
    }

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(true);
    }

    const handleSidebar = () => {
        setOpenSidebar(true);
    }

    return(
        <AppContext.Provider 
            value={{
                showPassword,
                openSidebar,
                openModal,
                savedModal,
                showConfirmPassword,
                loading,
                databaseDivs,
                selectDBType,
                dbType,
                showDbTable,
                dbPassword,
                editDatabaseModal,
                fetchedDatabase,
                handleDBPassword,
                handleEditDatabaseModal,
                getDatabaseById,
                handleShowDbTable,
                setDBType,
                toggleDBType,
                handleLoader,
                handleShowConfirmPassword, 
                setShowConfirmPassword,
                setSavedModal,
                setOpenSidebar,
                setOpenModal,
                setShowPassword,
                handleShowPassword,
                handleModal,
                handleSidebar
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

export const useGlobalContext = () => {
    return useContext(AppContext);
}

export { AppContext, AppProvider };