import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "../context/context";
import Loader from "../components/Loader";

const ForgotPassword = () => {
  const { loading, handleLoader } = useGlobalContext();
  const [emailData, setEmailData] = useState({
    email: "",
  });

  const { email } = emailData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
  };

  const handleSendEmail = async () => {
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/forgot-password",
        { email }
      );
      if (res.data.message === "Mail sent to your email address") {
        toast.success(res.data.message, {
          position: "top-right",
        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full">
          <div className="max-w-lg md:mx-auto mx-7 shadow-md rounded-md p-4 mt-28 bg-[#0e131f]">
            <ToastContainer autoClose={4000} />
            <h3 className="text-white text-center text-lg pb-2 font-bold border-b-2 border-gray-800 mb-4">
              Send Reset Link
            </h3>
            <div className="flex flex-col items-start gap-4">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="text-white text-base font-semibold"
                >
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={handleChange}
                  name="email"
                  className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-2 outline-none "
                />
              </div>
              <button
                type="button"
                onClick={handleSendEmail}
                className="text-white font-semibold text-base rounded-md p-3 register-btn"
              >
                Send
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ForgotPassword;
