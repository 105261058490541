import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const Register = () => {
  const initialState = {
    fullName: "",
    email: "",
    password: "",
  };
  const {
    showPassword,
    setShowPassword,
    handleShowPassword,
    loading,
    handleLoader,
  } = useGlobalContext();
  
  const [userData, setUserData] = useState(initialState);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const { fullName, email, password } = userData;

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "checkbox1") {
      setCheckbox1(checked);
    } else if (name === "checkbox2") {
      setCheckbox2(checked);
    }
  };

  const handleRegister = async () => {
    try {
      const formData = { fullName, email, password, checkbox1, checkbox2 };
      const res = await axios.post(
        "https://app.easify-ai.com/users/register",
        formData
      );
      if (res.data.message === "You have been registered successfully!") {
        toast.success(res.data.message, {
          position: "top-right",
        });
        localStorage.setItem("verification_status", "false");
        setTimeout(() => {
          window.location.href = "/verification";
        }, 2000);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full">
          <div className="max-w-lg md:mx-auto mx-7 shadow-md rounded-md p-4 mt-28 bg-[#0e131f]">
            <ToastContainer autoClose={3000} />
            <h3 className="text-white text-center text-lg pb-2 font-bold border-b-2 border-gray-800 mb-4">
              Register to your account
            </h3>
            <div className="flex flex-col items-start gap-4">
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="fullName"
                  className="text-white text-base font-semibold"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={fullName}
                  onChange={handleChange}
                  className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-2 outline-none "
                />
              </div>
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="email"
                  className="text-white text-base font-semibold"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-2 outline-none "
                />
              </div>
              <div className="w-full flex flex-col gap-2">
                <label
                  htmlFor="password"
                  className="text-white text-base font-semibold"
                >
                  Password
                </label>
                <div className="password-box bg-[#1f2937] rounded-md p-2 flex items-center justify-between">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className="w-full bg-transparent text-white text-sm outline-none "
                  />
                  {showPassword ? (
                    <i
                      className="fas fa-eye-slash cursor-pointer text-white text-sm mr-1"
                      onClick={() => setShowPassword(false)}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye cursor-pointer text-white text-sm mr-1"
                      onClick={handleShowPassword}
                    ></i>
                  )}
                </div>
                <div className="flex items-center pt-4">
                  <input
                    type="checkbox"
                    checked={checkbox1}
                    onChange={handleCheckboxChange}
                    name="checkbox1"
                    className="w-4 h-4 text-blue-600 border-gray-500 bg-gray-600 rounded-md cursor-pointer"
                  />
                  <label
                    htmlFor="checkbox1"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    I agree with the{" "}
                    <Link
                      to="https://easify-ai.com/term-conditions/"
                      target="__blank"
                      className="text-blue-600 hover:underline"
                    >
                      terms and conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="https://easify-ai.com/privacy-policy/"
                      target="__blank"
                      className="text-blue-600 hover:underline"
                    >
                      Privacy and Policy
                    </Link>
                  </label>
                </div>
                <div className="flex items-center pt-2">
                  <input
                    type="checkbox"
                    checked={checkbox2}
                    onChange={handleCheckboxChange}
                    name="checkbox2"
                    className="w-4 h-4 text-blue-600 bg-gray-600 border-gray-500 rounded-md cursor-pointer"
                  />
                  <label
                    htmlFor="checkbox2"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    I want to recieve the marketing updates from Easify AI
                  </label>
                </div>
              </div>
              <div className="flex items-center">
                <span className="text-base text-white font-semibold">
                  Already have an account?
                </span>
                <Link
                  to="/login"
                  className="text-base text-purple-300 font-semibold pl-1"
                >
                  Login
                </Link>
              </div>
              <button
                type="button"
                className="text-white font-semibold text-base rounded-md p-3 register-btn"
                onClick={handleRegister}
              >
                Sign Up
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Register;
