import React from 'react';
import { Link } from 'react-router-dom';
import { RiAdminLine } from "react-icons/ri";

const Header = () => {
  return (
    <header className='w-full fixed top-0 left-0 z-[999] transition shadow bg-[#0e131f]'>
      <nav className="flex items-center justify-between p-6">
        <Link to="/">
          <img src="/easify-ai-logo.png" alt="logo" className='w-16 md:w-20' />
        </Link>
        <div className="flex items-center gap-3">
          <Link to="/login" className='text-base font-semibold text-purple-300'>
            Login
          </Link>
          <Link to="/register" className='text-base text-white font-semibold register-btn rounded-md p-2'>
            Sign Up
          </Link>
          <Link to="/admin/login" className='flex items-center gap-1'>  
            <RiAdminLine size={20} className='text-purple-300 font-semibold' />
            <span className='text-purple-300 font-semibold'>Admin</span>
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default Header