import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context/context";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { RiQuestionnaireLine } from "react-icons/ri";
import Cookies from 'js-cookie';

const Sidebar = () => {
  const { openSidebar, setOpenSidebar, handleModal } = useGlobalContext();
  const [userData, setUserData] = useState([]);
  const [credits, setCredits] = useState(0);
  const token = localStorage.getItem("user_auth");

  const fetchUser = async () => {
    const formData = { token };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/getUser",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(res.data.user);
      setCredits(res.data.user.credits);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchUser();
    } else {
      console.log("Token is not present");
    }
  }, [token]);

  const handleLogout = () => {
    localStorage.removeItem("user_auth");
    localStorage.removeItem("session_id");
    localStorage.removeItem("verification_status");
    Cookies.remove("status");
    Cookies.remove("credits");
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  return (
    <>
      <aside
        className={
          openSidebar
            ? "bg-[#0e131f] fixed top-0 left-0 bottom-0 h-full w-full md:w-64 translate-x-0 z-[99] transition-all"
            : "bg-[#0e131f] fixed top-0 left-0 bottom-0 h-full w-full md:w-64 translate-x-[-100%] z-[99] transition-all"
        }
      >
        <div className="w-full min-h-full pt-6">
          <div className="w-full flex items-center justify-between border-b-2 px-6 pb-6 border-gray-700">
            <Link>
              <img src="/easify-ai-logo.png" alt="logo" className="w-16" />
            </Link>
            <i
              className="fas fa-times text-white text-lg cursor-pointer"
              onClick={() => setOpenSidebar(false)}
            ></i>
          </div>
          <div className="w-full">
            <ul className="w-[inherit]">
              <li className="w-full list-none pl-6 py-4 border-b text-white border-gray-600 hover:underline hover:text-purple-600">
                <Link to="/data-ai" className="text-base font-semibold">
                  Data AI
                </Link>
              </li>
              <button
                onClick={() => {
                  handleModal();
                  setOpenSidebar(false);
                }}
                type="button"
                className="w-full pl-6 py-4 flex items-center gap-1 text-white font-semibold text-base border-b border-gray-600 hover:underline hover:text-purple-600"
              >
                <AiOutlinePlus />
                <span>Create Connection</span>
              </button>
              <li className="w-full list-none pl-6 py-4 border-b text-white border-gray-600 hover:underline hover:text-purple-600">
                <Link to="/history" className="flex items-center gap-2">
                  <i className="fa-solid fa-table text-base"></i>
                  <span className="text-base font-semibold">History</span>
                </Link>
              </li>
              <li className="w-full list-none pl-6 py-4 text-white hover:underline hover:text-purple-600">
                <Link
                  to="https://easify-ai.com/faq"
                  target="__blank"
                  className="flex items-center gap-2"
                >
                  <RiQuestionnaireLine size={20} />
                  <span className="text-base font-semibold">FAQs</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full flex flex-col pt-6 px-6 border-t-2 border-gray-700">
            <Link
              to="/profile"
              type="button"
              className="mt-4 register-btn text-center text-white font-semibold text-base rounded-md p-2"
            >
              Upgrade Plan <i className="fas fa-chevron-right pl-1"></i>
            </Link>
            <div className="flex items-center justify-between mt-2">
              <div className="w-full flex flex-col">
                <span className="font-semibold text-sm text-[#d1d5db]">
                  {userData.fullName}
                </span>
                <div className="flex items-center gap-2 mt-2">
                  <Link
                    to="/profile"
                    className="bg-[#8b5cf6] rounded-md text-sm font-semibold w-[28px] h-[28px] flex items-center justify-center text-center text-white"
                  >
                    {credits}
                  </Link>
                  <span className="text-xs text-white font-semibold">
                    Credits
                  </span>
                  <i
                    className="fa-solid fa-right-from-bracket text-base ml-auto cursor-pointer text-white"
                    onClick={handleLogout}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
