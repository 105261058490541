import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "../context/context";
import Loader from "../components/Loader";

const Verification = () => {
  const { loading, handleLoader } = useGlobalContext();
  const initialState = {
    otp: "",
  };
  const [otpData, setOtpData] = useState(initialState);
  const { otp } = otpData;

  const handleChange = (e) => {
    setOtpData({ ...otpData, [e.target.name]: e.target.value });
  };

  const verifyOTP = async () => {
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/verify-otp",
        { otp }
      );
      if (res.data.message === "Email Verified Successfully") {
        toast.success(res.data.message, {
          position: "top-right",
        });
        localStorage.setItem("verification_status", "true");
        setTimeout(() => {
          window.location.href = "/login";
        }, 1000);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="w-full">
          <div className="max-w-lg md:mx-auto mx-7 shadow-md rounded-md p-4 mt-28 bg-[#0e131f]">
            <ToastContainer autoClose={4000} />
            <h3 className="text-white text-center text-lg pb-2 font-bold border-b-2 border-gray-800 mb-4">
              Email Verification
            </h3>
            <div className="w-full flex flex-col gap-2">
              <h2 className="text-white text-lg text-center font-semibold">
                Welcome to Our Website!
              </h2>
              <p className="text-white text-sm text-center font-medium mb-4">
                Thank you for signing up! To complete your registration, please
                verify your email address by clicking the button below:
              </p>
              <label
                htmlFor="fullName"
                className="text-white text-base font-semibold"
              >
                Enter OTP
              </label>
              <input
                type="text"
                name="otp"
                onChange={handleChange}
                className="form-control w-full bg-[#1f2937] text-white text-sm rounded-md p-2 outline-none "
              />
              <button
                type="button"
                className="text-white font-semibold text-base rounded-md p-3 register-btn"
                onClick={verifyOTP}
              >
                Verify OTP
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Verification;
