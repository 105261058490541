import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import { BsDatabaseGear, BsPatchPlus, BsBookHalf } from "react-icons/bs";
import { RiQuestionnaireLine } from "react-icons/ri";
import DatabaseModal from "../components/DatabaseModal";
import Loader from "../components/Loader";
import Cookies from "js-cookie";

const History = () => {
  const token = localStorage.getItem("user_auth");
  const {
    handleSidebar,
    setOpenSidebar,
    handleModal,
    openModal,
    loading,
    handleLoader,
  } = useGlobalContext();

  const [userData, setUserData] = useState([]);
  const [credits, setCredits] = useState(0);
  const [fetchHistory, setFetchHistory] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [buttonAccordion, setButtonAccordion] = useState(false);
  const [copied, setCopied] = useState(false);

  const toggleButtonAccordion = () => {
    setButtonAccordion(true);
  };

  const onItemClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const getHistory = async () => {
    const formData = { token };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/getHistory",
        formData
      );
      if (res) {
        console.log(res);
        setFetchHistory(res.data.userHistory);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async () => {
    const formData = { token };
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/getUser",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(res.data.user);
      setCredits(res.data.user.credits);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setOpenSidebar(false);
    if (token) {
      fetchUser();
    } else {
      console.log("You have to login");
    }
  }, [token]);

  useEffect(() => {
    handleLoader();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user_auth");
    localStorage.removeItem("session_id");
    localStorage.removeItem("verification_status");
    Cookies.remove("status");
    Cookies.remove("credits");
    setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const copyToClipboard = (answer) => {
    const textToCopy = answer;
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <>
      <Sidebar />
      {openModal && <DatabaseModal />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <aside className="bg-[#0e131f] dk-sidebar hidden md:block overflow-y-scroll fixed top-0 left-0 bottom-0 h-full w-64 z-50">
            <div className="w-full h-full flex flex-col items-start justify-between py-10 px-5">
              <div className="w-full flex flex-col items-start">
                <Link
                  to="/data-ai"
                  className="w-full pb-2 mb-4 border-b border-gray-600"
                >
                  <img
                    src="/easify-ai-logo.png"
                    alt="logo"
                    className="w-[70px]"
                  />
                </Link>
                <ul className="w-[inherit] pt-8">
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsDatabaseGear size={18} />
                    <Link to="/data-ai">Data AI</Link>
                  </li>
                  <li
                    onClick={handleModal}
                    className="text-white cursor-pointer text-base font-semibold flex items-center gap-2 mb-6"
                  >
                    <BsPatchPlus size={18} />
                    <span>Create Connection</span>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2 mb-6">
                    <BsBookHalf size={18} />
                    <Link to="/history">History</Link>
                  </li>
                  <li className="text-white text-base font-semibold flex items-center gap-2">
                    <RiQuestionnaireLine size={18} />
                    <Link to="https://easify-ai.com/faq" target="_blank">
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="w-full flex flex-col border-t-2 border-gray-600">
                <Link
                  to="/profile"
                  type="button"
                  className="mt-3 text-center register-btn text-white font-semibold text-base rounded-md p-2"
                >
                  Upgrade Plan <i className="fas fa-chevron-right pl-1"></i>
                </Link>
                <div className="flex items-center justify-between mt-2">
                  <div className="w-full flex flex-col">
                    <Link
                      to="/profile"
                      className="font-semibold text-sm text-[#d1d5db]"
                    >
                      {userData.fullName}
                    </Link>
                    <div className="flex items-center gap-2 mt-2">
                      <Link
                        to="/profile"
                        className="bg-[#8b5cf6] rounded-md text-sm font-semibold w-[28px] h-[28px] flex items-center justify-center text-white"
                      >
                        {credits}
                      </Link>
                      <span className="text-xs text-white font-semibold">
                        Credits
                      </span>
                      <i
                        className="fa-solid fa-right-from-bracket text-base ml-auto cursor-pointer text-white"
                        onClick={handleLogout}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>

          <header className="w-full bg-transparent block md:hidden">
            <div className="flex items-center justify-between py-4 px-6 border-b-2 border-gray-800">
              <Link to="/history" className="w-full">
                <img
                  src="/easify-ai-logo.png"
                  alt="logo"
                  className="w-[70px]"
                />
              </Link>
              <i
                className="fa-solid fa-bars text-white text-lg cursor-pointer"
                onClick={handleSidebar}
              ></i>
            </div>
          </header>

          <div className="flex flex-col items-start justify-center py-14 px-6 md:py-20 md:px-72">
            <h3 className="text-white text-2xl font-bold mb-4">History</h3>
            <div className="w-full">
              {fetchHistory.slice(1).map((item, index) => {
                const parts = item.entry.split("||");

                const question = parts[0].replace("Question:", "").trim();
                const answer = parts[1].replace("Answer:", "").trim();
                // const services = parts[2].replace("Services:", "").trim();
                // const company = parts[3].replace("Company:", "").trim();

                return (
                  <div key={index} className="mb-4">
                    <button
                      onClick={() => onItemClick(index)}
                      className="flex items-center justify-between transition-all px-4 py-2 font-semibold bg-gray-900 text-gray-300 hover:bg-gray-300 hover:text-violet-900 w-full"
                    >
                      <span>{new Date(item.timestamp).toLocaleString()}</span>
                      <span className="hidden md:block">{question}</span>
                      <span className="transform rotate-0 text-sm">
                        {activeIndex === index ? "▲" : "▼"}
                      </span>
                    </button>
                    {activeIndex === index && (
                      <div className="p-4 bg-[#1f2937] text-gray-300 relative">
                        {/* {question && (
                        <h3 className="text-base font-semibold border-b border-gray-600 pb-4">
                          Question: {question}
                        </h3>
                    )} */}
                        {copied ? (
                          <i
                            className="fa-solid fa-circle-check absolute cursor-pointer top-4 right-4"
                            onClick={() => setCopied(false)}
                          ></i>
                        ) : (
                          <i
                            className="fa-solid fa-pen-to-square absolute cursor-pointer top-4 right-4"
                            onClick={() => copyToClipboard(answer)}
                          ></i>
                        )}
                        <p className="text-gray-300 mt-6">{answer}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default History;
