import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../context/context";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";

import DatabaseLoader from "./DatabaseLoader";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

const DatabaseList = ({ selectedDatabase, handleSelectDatabase }) => {
  const token = localStorage.getItem("user_auth");
  const { setShowDbTable, handleEditDatabaseModal, getDatabaseById } = useGlobalContext();
  const [databaseList, setDatabaseList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleLoader = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const getDatabaseList = async () => {
    try {
      const res = await axios.post(
        "https://app.easify-ai.com/users/database-list",
        { token }
      );
      setDatabaseList(res.data.databaseList);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDatabase = async (databaseId) => {
    try {
      const res = await axios.delete("https://app.easify-ai.com/users/remove-db", {
        data: { token, databaseId }
      });
      if (res.data.message === "Database removed successfully") {
        getDatabaseList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDatabaseList();
    handleLoader();
  }, []);

  return (
    <>
      <div
        className="relative z-[99]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-y-scroll saved-database-modal rounded-lg bg-[#0e131f] text-left shadow-xl transition-all sm:my-8 w-full md:max-w-4xl">
              <div className="bg-[#0e131f] px-6 py-6">
                <div className="flex items-center justify-between w-full border-b pb-3 border-gray-700">
                  <h3 className="text-white text-base md:text-xl font-semibold">
                    Saved Databases
                  </h3>
                  <RxCrossCircled
                    onClick={() => setShowDbTable(false)}
                    size={22}
                    className="text-red-400 font-bold cursor-pointer"
                  />
                </div>
                {loading ? (
                  <DatabaseLoader />
                ) : (
                  <ul className="grid w-full gap-4 md:grid-cols-3 mt-3">
                  {databaseList.map((item, index) => (
                    <li key={index}>
                      <input
                        type="radio"
                        id={item._id}
                        name="database"
                        value={item.connection_string}
                        onChange={handleSelectDatabase}
                        checked={selectedDatabase === item.connection_string}
                        className="hidden peer"
                      />
                      <label
                        htmlFor={item._id}
                        className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                      >
                        <div className="block">
                          <div className="w-full text-lg font-semibold">
                            {item.name}
                          </div>
                          <div className="w-full">{item.database}</div>
                        </div>
                        <div className="flex items-center gap-4">
                          {item.name === "Mongo DB" && (
                            <img
                              src="/leaf.png"
                              className="w-10 object-cover"
                              alt={item.name}
                            />
                          )}
                          {item.name === "MySQL" && (
                            <img
                              src="/programing.png"
                              className="w-10 object-cover"
                              alt={item.name}
                            />
                          )}
                          {item.name === "SQLite" && (
                            <img
                              src="/database.png"
                              className="w-10 object-cover"
                              alt={item.name}
                            />
                          )}
                          {(item.name === "Postgre SQL" ||
                            item.name === "postgres") && (
                            <img
                              src="/postgre.png"
                              className="w-10 object-cover"
                              alt={item.name}
                            />
                          )}
                          <div className="flex flex-col items-center gap-3">
                            <button
                              type="button"
                              disabled={index === 0 ? true : false}
                              onClick={() => {
                                getDatabaseById(item._id);
                                handleEditDatabaseModal();
                              }}
                            >
                              <FaPencilAlt
                                size={30}
                                className={`p-2 text-white bg-green-500 ${index === 0 ? 'opacity-70' : 'opacity-100'} rounded`}
                              />
                            </button>
                            <button
                              type="button"
                              disabled={index === 0 ? true : false}
                              onClick={() => deleteDatabase(item._id)}
                            >
                              <FaTrashAlt
                                size={30}
                                className={`p-2 text-white bg-red-500 ${index === 0 ? 'opacity-70' : 'opacity-100'} rounded`}
                              />
                            </button>
                          </div>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatabaseList;
