import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteModal = ({ setOpenDeleteModal, userId }) => {
  const [message, setMessage] = useState("");

  const handleDeleteUser = async (id) => {
    try {
      const res = await axios.post("https://app.easify-ai.com/admin/delete_user", { id });
      setMessage(res.data.message);
      if(res.data.message === "User Deleted Successfully") {
        toast.success(res.data.message, {
          position: "top-right"
        });
      }
      else {
        toast.error(res.data.message, {
          position: "top-right"
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div
        className="relative z-[99]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <ToastContainer autoClose={4000}/>
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-[#0e131f] text-left shadow-xl transition-all sm:my-8 w-full md:max-w-2xl">
              <div className="bg-[#0e131f] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="flex items-center justify-between w-full border-b pb-3 border-gray-700">
                  <div className="flex items-center gap-2">
                    <h3 className="text-white font-semibold text-base">
                      Are you sure you want to delete this user?
                    </h3>
                  </div>
                </div>
                {message && (
                  <div className="text-red-400 text-sm font-semibold pt-4">{message}</div>
                )}
              </div>
              <div className="bg-[#1f2531] py-4 md:pr-6 pr-5 flex gap-4 items-center justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center transition rounded-md bg-[#8d57ff] px-3 w-20 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#1100d0]"
                  onClick={() => handleDeleteUser(userId)}
                >
                  Delete
                </button>
                <button
                  type="button"
                  onClick={() => setOpenDeleteModal(false)}
                  className="inline-flex justify-center transition rounded-md bg-white px-3 w-20 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
