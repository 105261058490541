import axios from "axios";
import React, { useEffect, useState } from "react";

import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";

import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import Loader from "../components/Loader";
import { useGlobalContext } from "../context/context";

const AdminDashboard = () => {
  const { loading, handleLoader } = useGlobalContext();
  const [userData, setUserData] = useState([]);
  const adminToken = localStorage.getItem("admin_token");

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userId, setUserId] = useState("");

  const handleEditModal = (id) => {
    setOpenEditModal(true);
    setUserId(id);
  };

  const handleDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setUserId(id);
  };

  const fetchUsers = async () => {
    try {
      const res = await axios.post("https://app.easify-ai.com/admin/users", {
        adminToken,
      });
      if (res) {
        console.log(res);
        setUserData(res.data.users);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (adminToken) {
      fetchUsers();
    } else {
      console.log("Token required to access this page and the users");
    }
  }, []);

  useEffect(() => {
    handleLoader();
  }, []);

  return (
    <>
      {openEditModal && (
        <EditModal userId={userId} setOpenEditModal={setOpenEditModal} />
      )}
      {openDeleteModal && (
        <DeleteModal userId={userId} setOpenDeleteModal={setOpenDeleteModal} />
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-6xl pt-32 px-8 md:px-0 mx-auto">
          <div className="relative overflow-x-auto shadow-md rounded-md">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                No of Users ({userData.length})
                <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                  Browse a list of Users stay organized, keep in touch with the
                  users and more.
                </p>
              </caption>
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Credits
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Plan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Payment Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Terms and Conditions
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Marketing Updates
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData.length > 0 &&
                  userData.map((item, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 text-white font-medium text-base">
                        {item?.fullName}
                      </td>
                      <td className="px-6 py-4 font-medium">{item?.email}</td>
                      <td className="px-6 py-4 font-medium">{item?.credits}</td>
                      <td className="px-6 py-4 font-medium">
                        {item?.payment_status}
                      </td>
                      <td className="px-6 py-4 font-medium">{item?.plan}</td>
                      <td className="px-6 py-4 font-medium">
                        {item?.terms_conditions}
                      </td>
                      <td className="px-6 py-4 font-medium">
                        {item?.recieve_updates}
                      </td>
                      <td className="px-6 py-4 flex items-center gap-2">
                        <HiOutlinePencilAlt
                          onClick={() => handleEditModal(item._id)}
                          size={20}
                          className="cursor-pointer text-green-400"
                        />
                        <HiOutlineTrash
                          onClick={() => handleDeleteModal(item._id)}
                          size={20}
                          className="cursor-pointer text-red-400"
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
