import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import DataAi from "./pages/DataAi";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import History from "./pages/History";
import Verification from "./pages/Verification";
import ForgotPassword from "./pages/ForgotPassword";
import CreateNewPassword from "./pages/CreateNewPassword";

import AdminLogin from "./pages/AdminLogin";
import AdminDashboard from "./pages/AdminDashboard";

function App() {
  const token = !!localStorage.getItem("user_auth");
  const admin_token = !!localStorage.getItem("admin_token");
  const statusToken = !!localStorage.getItem("verification_status");

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/register"
            element={token ? <Navigate to="/data-ai" /> : <Register />}
          />
          <Route
            path="/login"
            element={
              token && statusToken !== "false" ? (
                <Navigate to="/data-ai" />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/verification"
            element={token ? <Navigate to="/data-ai" /> : <Verification />}
          />
          <Route
            path="/forgot-password"
            element={token ? <Navigate to="/data-ai" /> : <ForgotPassword />}
          />
          <Route
            path="/create-password"
            element={token ? <Navigate to="/data-ai" /> : <CreateNewPassword />}
          />
          <Route
            path="/data-ai"
            element={token ? <DataAi /> : <Navigate to="/login" />}
          />
          <Route
            path="/history"
            element={token ? <History /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={token ? <Profile /> : <Navigate to="/login" />}
          />
          <Route
            path="/cancel"
            element={token ? <Cancel /> : <Navigate to="/login" />}
          />
          <Route
            path="/success"
            element={token ? <Success /> : <Navigate to="/login" />}
          />
          <Route
            path="/admin/login"
            element={
              admin_token ? <Navigate to="/admin/dashboard" /> : <AdminLogin />
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              admin_token ? <AdminDashboard /> : <Navigate to="/admin/login" />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
